import "./Home.css";

const Home = () => {
  return (
    <div className="home-container">
      <img className="home-img" src="https://i.ytimg.com/vi/uMxnmGBGL78/maxresdefault.jpg" />
      <div>샴고양이 사용중에 궁금한게 또 있어요?</div>
      <div><a href="https://discord.gg/uuSPaYtF8W">샴고양이 DevServer</a>로 오십시오</div>
    </div>
  )
}

export default Home;
